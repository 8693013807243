import * as React from "react";
import { mobileOnly, desktopOnly } from "../../styles/global.module.scss";
import * as styles from "./HotelPage.module.scss";
// import { hotelTitle, banner, anchorMenuContainer, menuNav, menuNavItem, navLink, addressBarContainer, addressBar, phone } from "./HotelPage.module.scss";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sticky from "react-sticky-el";
import { Breadcrumb, CarouselItem, Col, Container, Row } from "react-bootstrap";
import useMobileDetect from "../../hooks/useMobileDetect";
import { GatsbyImage } from "gatsby-plugin-image";
import { getAddressStateName } from "../../services/address";
import { slugify } from "transliteration";
import { Carousel } from "react-bootstrap";
import { useWebFramed } from "../../hooks/useWebFramed";

const {
  container,
  hotelTitle,
  banner,
  anchorMenuContainer,
  menuNav,
  menuNavItem,
  menuItemActive,
  navLink,
  addressBarContainer,
  addressBar,
  phone,
  contentLeftAlign,
  stickyContainer,
  customSticky,
  mobilelAddressBar,
  mobileMenuContainer,
  breadCrumbCss,
  underLineText,
  awardsContainer,
} = styles;

export const StyledContainer = (props: {
  className?: string;
  children: JSX.Element;
  fluid: boolean | "sm" | "md" | "lg" | "xl";
}): JSX.Element => {
  return (
    <Container className={`${container} ${props.className}`} {...props}>
      {props.children}
    </Container>
  );
};
export const HotelTitle = (props: {
  title: string;
  hotelInfoFontColor: string;
  id?: string;
}): JSX.Element => {
  return (
    <h1
      id={props.id}
      className={hotelTitle}
      style={{ color: props.hotelInfoFontColor }}
    >
      {props.title}
    </h1>
  );
};

export const AgentLink = (props: any): JSX.Element => {
  const searchData = props.search;
  let adults = ``;
  let children = ``;
  let childages = ``;
  searchData.rooms.forEach((room: any) => {
    const childAges = room.childrenAges;
    if (adults) {
      adults += `,${room.adults}`;
    } else {
      adults += `${room.adults}`;
    }
    if (children) {
      children += `,${room.children}`;
      childages += `,${childAges.join("|")}`;
    } else {
      children += `${room.children}`;
      if (childAges.length) {
        childages += childAges.join("|");
      }
    }
  });

  let agentURL = `https://book.sonesta.com/?currency=USD&chain=${props.chain_id}&hotel=${props.hotel_code}`;
  agentURL += `&arrive=${searchData.checkin}&depart=${searchData.checkout}`;
  agentURL += `&rooms=${searchData.rooms.length}&adult=${adults}&child=${children}&childages=${childages}`;
  if (searchData.promotionCode) {
    agentURL += `&promo=${searchData.promotionCode}`;
  }
  return (
    <div className="agent-url">
      Travel Agents: Visit our{" "}
      <a
        id="partner-portal-link"
        href={agentURL}
        target={"_blank"}
        rel="noreferrer"
      >
        partner portal
      </a>{" "}
      to book with your agent ID.
    </div>
  );
};

export const Banner = (props: {
  children?: any;
  desktopImage: any;
  mobileImage: any;
}): JSX.Element | null => {
  const isMobile = useMobileDetect();
  const image = isMobile
    ? props.mobileImage[0].relationships?.field_media_image?.gatsbyImage
    : props.desktopImage[0]?.relationships?.field_media_image?.gatsbyImage;
  return image ? (
    <>
      <GatsbyImage
        image={image}
        alt="hotel banner"
        className={banner}
        imgStyle={{ opacity: 1, transition: "opacity 0.01s linear" }}
      />
      {props.children}
    </>
  ) : null;
};

export const AddressBarContainer = (props: {
  children: any;
  hotelInfoBgColor: string;
  hotelInfoFontColor: string;
}): JSX.Element => {
  return (
    <div
      className={addressBarContainer}
      style={{
        backgroundColor: props.hotelInfoBgColor,
        color: props.hotelInfoFontColor,
      }}
    >
      {props.children}
    </div>
  );
};

export const AddressBar = (props: {
  directionLink: string;
  phone: string;
  hotelInfoFontColor: string;
  address: {
    address_line1?: string;
    locality?: string;
    administrative_area?: string;
    postal_code?: string;
  };
}): JSX.Element => {
  const _address = props.address;
  const _directionsLink = props.directionLink;
  const _phone = props.phone;

  return (
    <div className={addressBar}>
      <a
        href={_directionsLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: props.hotelInfoFontColor }}
        id="hotel-address"
      >
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          color={props.hotelInfoFontColor}
        />
        <span
          className={desktopOnly}
          style={{ color: props.hotelInfoFontColor }}
        >
          {_address.address_line1}, {_address.locality},{" "}
          {_address.administrative_area} {_address.postal_code}
        </span>
        <span
          className={mobileOnly}
          style={{ color: props.hotelInfoFontColor }}
        >
          Get Directions
        </span>
      </a>
      {_phone !== null && (
        <a
          href={`tel:${_phone}`}
          className={`${phone}`}
          style={{ color: props.hotelInfoFontColor }}
          id="hotel-phone"
        >
          <FontAwesomeIcon
            icon={faPhoneAlt}
            className="me-2"
            color={props.hotelInfoFontColor}
          />
          <span
            className={desktopOnly}
            style={{ color: props.hotelInfoFontColor }}
          >
            {_phone}
          </span>
          <span
            className={mobileOnly}
            style={{ color: props.hotelInfoFontColor }}
          >
            Call Hotel
          </span>
        </a>
      )}
    </div>
  );
};
export const StickyContainer = (props: {
  children: any;
  logo: string;
}): JSX.Element => {
  const isWebFramed = useWebFramed();
  return (
    <Sticky
      className={stickyContainer}
      stickyClassName={customSticky}
      stickyStyle={{ top: props.logo && !isWebFramed ? "136px" : "96px" }}
      positionRecheckInterval={3}
    >
      {props.children}
    </Sticky>
  );
};

export const AnchorMenus = (props: {
  hotelInfoFontColor: string;
  tabId?: string | null;
  hotelInfoBgColor: string;
  anchors: Array<{ anchor: string; label: string }>;
  hotelInfoActiveColor: string;
  onClick: (e: any) => void;
}): JSX.Element => {
  const [hoveredAnchor, setHoveredAnchor] = React.useState(null);
  const handleAnchorClick = (event: any) => {
    const tabId = event.target.dataset.tabid;
    props.onClick && props.onClick(tabId);
  };

  const isMobile = useMobileDetect();
  const handleAnchorHover = (anchor) => {
    setHoveredAnchor(anchor);
  };

  const anchorLinks = props.anchors.map(
    (anchor: { anchor: string; label: string }, idx: number) => {
      const isActive = props.tabId === anchor.anchor;
      const isHovered = hoveredAnchor === anchor.anchor;
      return (
        <li
          className={`${menuNavItem} ${
            props.tabId === anchor.anchor ? menuItemActive : ""
          }`}
          style={{ color: props.hotelInfoActiveColor }}
          key={`anchor-menu-${idx}`}
        >
          <button
            role="link"
            id={`navLink-${anchor.anchor}`}
            className={navLink}
            data-tabid={anchor.anchor}
            style={{
              color: props.hotelInfoFontColor,
              textDecoration:
                (isActive || isHovered) &&
                `underline ${props.hotelInfoActiveColor}`,
              textUnderlinePosition: (isActive || isHovered) && "under",
              textDecorationThickness: (isActive || isHovered) && "3px",
            }}
            onClick={handleAnchorClick}
            onMouseEnter={() => handleAnchorHover(anchor.anchor)}
            onMouseLeave={() => handleAnchorHover(null)}
          >
            {anchor.label}
          </button>
        </li>
      );
    }
  );

  return (
    <div className={anchorMenuContainer}>
      <ul className={`${menuNav} ${contentLeftAlign}`}>{anchorLinks}</ul>
    </div>
  );
};

export const MobilelAddressBar = (props: {
  children: any;
  hotelInfoBgColor: string;
  hotelInfoFontColor: string;
}): JSX.Element => {
  return (
    <div
      className={mobilelAddressBar}
      style={{
        backgroundColor: props.hotelInfoBgColor,
        color: props.hotelInfoFontColor,
      }}
    >
      {props.children}
    </div>
  );
};
export const MobileMenuContainer = (props: {
  children: any;
  hotelInfoBgColor: string;
}): JSX.Element => {
  return (
    <div
      className={mobileMenuContainer}
      style={{ backgroundColor: props.hotelInfoBgColor }}
    >
      {props.children}
    </div>
  );
};
const BreadcrumbCreator = ({ hotelBreadCrumbProps }: any): JSX.Element => (
  <div className={"bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 position-relative"}>
    <div className={"container-sm"}>
      <Breadcrumb className={breadCrumbCss}>
        {hotelBreadCrumbProps.map(
          (item: {
            href: string;
            underlineLink: any;
            title: string;
            showCrumb: boolean;
          }) => {
            const props =
              item.href === ""
                ? {
                    className: `${item.underlineLink ? underLineText : ""}`,
                    active: true,
                  }
                : {
                    className: `${item.underlineLink ? underLineText : ""}`,
                    href: item.href,
                  };
            return item.showCrumb ? (
              <Breadcrumb.Item id={slugify(item.title)} {...props}>
                {" "}
                {item.title}
              </Breadcrumb.Item>
            ) : null;
          }
        )}
      </Breadcrumb>
    </div>
  </div>
);
export const HotelPageBreadCrumb = (props: any): JSX.Element => {
  const { _hotel, _queriedLinks, _location } = props.BreadcrumbDetails;
  const countriesNames: { [Key: string]: string } = {
    US: "United States",
    CA: "Canada",
    CL: "Chile",
    CO: "Colombia",
    EC: "Ecuador",
    EG: "Egypt",
    PE: "Peru",
    SX: "Sint Maarten",
  };
  const _address = _hotel.address || {};
  const countryCode = _hotel.address?.country_code;
  const _allCityLinks = _queriedLinks.filter(
    (city: { relationships: { field_sites: any[] } }) =>
      city.relationships?.field_sites.length === 0 ||
      city.relationships?.field_sites?.some(
        (e: { machine_name: string }) => e.machine_name === "sonesta"
      )
  );
  const _cityLinks = _allCityLinks.filter(
    (city: { fields: { path: { alias: string | string[] } } }) =>
      city.fields.path.alias.includes(`/${countryCode.toLowerCase()}/`)
  );
  const convertToWord = (stringVal: string) => {
    return stringVal
      .split("-")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");
  };
  let statePageLink = "";
  let cityPageLink = "";
  let cityName = "[N/A]";
  let stateName = "[N/A]";
  const matchedLocality = _cityLinks.filter(
    (item: { name: string; fields: { path: { alias: string } } }) =>
      slugify(item.name.split(",")[0]) === slugify(_address.locality) ||
      item.fields.path.alias.split("/")[
        item.fields.path.alias.split("/").length - 1
      ] === slugify(_address.locality)
  );
  if (matchedLocality.length > 0) {
    const foundPath = matchedLocality[0].fields.path;
    if (foundPath.alias.split("/").length === 4) {
      //we dont have a state for this hotel
      statePageLink = "";
      cityPageLink = countryCode !== "EC" ? foundPath.alias : "";
      stateName = "[N/A]";
      cityName = _address.locality;
    } else {
      //we found the exact state and city redirect paths for given hotel
      statePageLink = foundPath.parent_alias;
      cityPageLink = foundPath.alias;
      stateName =
        countryCode === "CO"
          ? getAddressStateName(countryCode, _address.administrative_area)
          : convertToWord(
              foundPath.parent_alias.split("/")[
                foundPath.parent_alias.split("/").length - 1
              ]
            );
      cityName = _address.locality;
    }
  }
  const matchedState =
    matchedLocality.length === 0
      ? _cityLinks.filter(
          (item: { name: string }) =>
            slugify(_hotel.name.toLowerCase()).indexOf(
              slugify(item.name.split(",")[0].toLowerCase())
            ) > -1
        )
      : [];
  if (matchedState.length > 0) {
    //we found a hotel with no dedicated city page belonging to state/province
    const matchedStatepath = matchedState[0].fields.path;
    cityPageLink = "";
    statePageLink = matchedStatepath.parent_alias;
    cityName = _address.locality ? _address.locality : "[N/A]";
    stateName =
      countryCode === "CO"
        ? getAddressStateName(countryCode, _address.administrative_area)
        : convertToWord(
            matchedStatepath.parent_alias.split("/")[
              matchedStatepath.parent_alias.split("/").length - 1
            ]
          );
  }
  if (matchedState.length === 0 && matchedLocality.length === 0) {
    //this page contains no dedicated state link or  city link.
    //check if there exists city and state/province/governate name in url,
    //generally if state has path it is trimmed to legth 2 or 3 and added in url
    //Eg aswan-governorate/nasr-city-cairo
    const path = _location?.pathname && _location.pathname.split("/");
    stateName =
      path.length === 5 && path[2].length > 3
        ? convertToWord(path[2])
        : "[N/A]";
    cityName = _address.locality ? _address.locality : "[N/A]";
  }
  const hotelBreadCrumbProps = [
    {
      title: _hotel.relationships.brand_id.name,
      href: _hotel.relationships.brand_id.path.alias,
      underlineLink: true,
      showCrumb: true,
    },
    {
      title: countriesNames[countryCode],
      href: "",
      underlineLink: false,
      showCrumb: true,
    },
    {
      title: stateName,
      href: statePageLink,
      underlineLink: statePageLink ? true : false,
      showCrumb: stateName === "[N/A]" ? false : true,
    },
    {
      title: cityName,
      href: cityPageLink,
      underlineLink: cityPageLink ? true : false,
      showCrumb: true,
    },
    {
      title: _hotel.name,
      href: "",
      underlineLink: false,
      showCrumb: true,
    },
  ];
  return <BreadcrumbCreator hotelBreadCrumbProps={hotelBreadCrumbProps} />;
};

export const HotelAwardsSection = (props: any): JSX.Element => {
  const isMobile = useMobileDetect();
  const [index, setIndex] = React.useState(0);
  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };
  return (
    <Container fluid="sm" className={awardsContainer}>
      <Row>
        <h3 className="hotel-award-title">Awards & Recognition</h3>
      </Row>

      {!isMobile ? (
        <Row className="award-row">
          {props.awards && props.awards.length < 3 && (
            <div
              className="hr-award"
              style={
                props.awards.length === 1
                  ? { width: "100px" }
                  : { width: "179px" }
              }
            ></div>
          )}
          {props.awards.map((award: any) => {
            return (
              <Col
                className="award-col"
                id={`hotel-award-${award.id}`}
                key={`hotel-award-${award.id}`}
              >
                <GatsbyImage
                  className="desktop-image"
                  alt={award.field_award_caption}
                  image={
                    award.relationships.field_award_image.relationships
                      .field_media_image.gatsbyImage
                  }
                />
                <div className="award-title">{award.field_award_caption}</div>
              </Col>
            );
          })}
          {props.awards && props.awards.length < 3 && (
            <div
              className="hr-award"
              style={
                props.awards.length === 1
                  ? { width: "100px" }
                  : { width: "179px" }
              }
            ></div>
          )}
        </Row>
      ) : (
        <Carousel
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}
          indicators={true}
          controls={false}
          fade
        >
          {props.awards.map((award: any) => {
            return (
              <CarouselItem key={award.id} id={award.id}>
                <GatsbyImage
                  className="mobile-image"
                  alt={award.field_award_caption}
                  image={
                    award.relationships.field_award_image.relationships
                      .field_media_image.gatsbyImage
                  }
                />
                <Carousel.Caption>
                  <div>{award.field_award_caption}</div>
                </Carousel.Caption>
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
    </Container>
  );
};
